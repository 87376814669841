import Rails from '@rails/ujs'
import SecureImage from './components/SecureImage'
import { getLocalStorageValue, setLocalStorageValue } from '../utils/storage'
const Vue = window.vue

document.addEventListener('DOMContentLoaded', () => {
  const $el = document.getElementById('shop-vue')

  if ($el) {
    const CURRENT_CATEGORY_ID = parseInt($el.dataset.categoryId)
    const CATEGORIES_PATH = $el.dataset.categoriesPath
    const SEARCH_TERM = $el.dataset.searchTerm
    const MOBILE_CATEGORIES = document.getElementById('category-scroll-mobile')
    const NOT_FOUNDS = document.getElementById('not_found_products')
    let searchGlobal = ''

    new Vue({
      el: $el,
      data: {
        categories: [],
        categoriesFilter: [],
        selectedCategory: { id: CURRENT_CATEGORY_ID },
        selectedViewCategory: { id: CURRENT_CATEGORY_ID },
        products: [],
        loadingCategories: true,
        loadingProducts: false,
        nextPage: 0,
        enabledInfinityLoader: false,
        observer: null,
        searchTermCategories: '',
        positionsCategories: [],
        showAsGallery: getLocalStorageValue('showAsGallery') === 'true' ? true : false,
        productPreview: ''
      },
      mounted () {
        this.loadCategories()
        window.eventBus.$on('searchStarted', (searchParam) => {
          if (searchParam !== searchGlobal) {
            searchGlobal = searchParam
            if (this.selectedCategory.id !== -1) {
              this.selectedCategory = this.categories[0]
            } else {
              this.nextPage = 0
              this.products = []
              this.loadProductsByCategory(this.selectedCategory, searchParam)
            }
          } else {
            window.eventBus.$emit('searchFinished')
          }
        })
        if (SEARCH_TERM !== '') {
          searchGlobal = SEARCH_TERM
          window.searchVue.searchTerm = SEARCH_TERM
        }

        window.addEventListener('scroll', this.setPosition)
        setTimeout( () => {
          document.getElementById('load-landing').classList.remove('d-block')
          document.getElementById('load-landing').classList.add('d-none')
        }, 10)
      },
      beforeDestroy () {
        this.disableInfinityLoader()
        window.removeEventListener('scroll', this.setPosition)
      },
      components: { 'secure-image': SecureImage },
      watch: {
        selectedCategory () {
          this.nextPage = 0
          this.products = []
          NOT_FOUNDS.classList.add('d-none')
          this.selectedViewCategory = this.selectedCategory
          if (this.selectedCategory.id !== -1) {
            searchGlobal = ''
            window.searchVue.searchTerm = ''
          }
          this.loadProductsByCategory(this.selectedCategory, searchGlobal)

          if (this.selectedCategory && this.selectedCategory.id) {
            if (this.selectedCategory.id === -1) {
              if (SEARCH_TERM === '') {
                history.pushState({ category_id: this.selectedCategory.id }, CATEGORIES_PATH, CATEGORIES_PATH)
              }
            }
          }
        },
        searchTermCategories () {
          this.categoriesFilter = this.categories.filter(x => x.name.includes(this.searchTermCategories.toUpperCase()))
        }
      },
      computed: {
        categoryName () {
          return this.selectedCategory && this.selectedCategory.name ? this.selectedCategory.name.toLowerCase() : ''
        }
      },
      methods: {
        activeInfinityLoader () {
          if (this.$refs.infinityLoader && !this.enabledInfinityLoader) {
            this.enabledInfinityLoader = true
            Promise.resolve(
              typeof window.IntersectionObserver !== 'undefined'
                ? window.IntersectionObserver
                : import('intersection-observer')
            )
              .then(() => {
                this.observer = new window.IntersectionObserver((entries) => {
                  const { isIntersecting } = entries[0]
                  if (isIntersecting && !this.loadingProducts) {
                    this.loadProductsByCategory(this.selectedCategory, searchGlobal)
                  }
                })
                this.observer.observe(this.$refs.infinityLoader)
              })
              .catch((error) => {
                console.error('Error: ', error)
              })
          }
        },
        setPosition () {
          if (this.selectedCategory.id !== -1 || this.positionsCategories.length === 0 || MOBILE_CATEGORIES === null) return
          const posTop = document.documentElement.scrollTop
          const foundPosition = this.positionsCategories.filter(x => posTop >= x.position)
          const selectedPosition = posTop >= this.positionsCategories[0].position ? foundPosition[foundPosition.length - 1].categoryId : '-1'
          const currentCategory = this.categories.find(x => x.id === parseInt(selectedPosition))
          if (this.selectedViewCategory === currentCategory) return
          const xPos = document.getElementById(`category-link-${currentCategory.id}`)
          document.getElementById('category-scroll-mobile').scrollLeft = xPos.offsetLeft + ((MOBILE_CATEGORIES.offsetWidth - xPos.offsetWidth) / 2)
          this.selectedViewCategory = currentCategory
        },
        loadPositions () {
          if (this.selectedCategory.id !== -1 || MOBILE_CATEGORIES === null) return
          const categoriesPositions = document.querySelectorAll('.category-anchors')
          this.positionsCategories = []
          categoriesPositions.forEach((category) => {
            const position = { value: category.id, position: category.offsetTop, categoryId: category.dataset.categoryId }
            this.positionsCategories.push(position)
          })
        },
        disableInfinityLoader () {
          this.enabledInfinityLoader = false
          if (this.observer) {
            this.observer.disconnect()
          }
        },
        loadCategories () {
          Rails.ajax({
            url: CATEGORIES_PATH + '.json',
            type: 'GET',
            dataType: 'json',
            beforeSend: () => {
              this.loadingCategories = true
              return true
            },
            success: (categories) => {
              this.categories = categories
              this.categoriesFilter = categories
              if (categories && categories.length > 0) {
                this.selectedCategory = (CURRENT_CATEGORY_ID) ? categories.find(category => category.id === CURRENT_CATEGORY_ID) : categories[0]
              }
            },
            error: (error) => {
              console.error('Error loading categories: ', error)
            },
            complete: () => {
              this.loadingCategories = false
            }
          })
        },
        loadProductsByCategory (category = {}, searchTerm = '') {
          if (category && category.url) {
            Rails.ajax({
              url: category.url,
              type: 'GET',
              dataType: 'json',
              data: `page=${this.nextPage}&search=${searchTerm}`,
              beforeSend: () => {
                this.loadingProducts = true
                return true
              },
              success: (products) => {
                this.products = this.products.concat(products)
                if (this.products.length === 0) {
                  NOT_FOUNDS.classList.remove('d-none')
                }
                window.eventBus.$emit('searchFinished')
                this.$nextTick(() => {
                  this.loadPositions()
                })
                if (products.length >= 20) {
                  this.nextPage++
                  if (!this.enabledInfinityLoader) {
                    this.activeInfinityLoader()
                  }
                } else {
                  this.disableInfinityLoader()
                }
              },
              error: (error) => {
                console.error('Error loading products: ', error)
              },
              complete: () => {
                this.loadingProducts = false
              }
            })
          }
        },
        selectCategory (e, category) {
          e.preventDefault()
          if ( this.loadingProducts ) return
          this.selectedCategory = category
          window.history.pushState({ category_id: this.selectedCategory.id }, this.selectedCategory.name, this.selectedCategory.url)
        },
        setAsGallery () {
          setLocalStorageValue('showAsGallery', true)
          this.showAsGallery = true
        },
        setAsList () {
          setLocalStorageValue('showAsGallery', false)
          this.showAsGallery = false
        },
        previewImage (product) {
          window.jQuery('#previewModal').modal()
          this.productPreview = product
        }
      }
    })
  }
})
